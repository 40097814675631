import type { ReactElement } from 'react'

import useRootData from '~/hooks/useRootData'
import RenewSubscriptionPanel from '~/modules/account/components/RenewSubscriptionPanel'
import Banner from '~/components/Banner'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import SignUpForm from '~/modules/signup/components/SignUpForm'
import ResendActivationLinkButton from '~/modules/signup/components/ResendActivationLinkButton'
import type { ContentError } from '~/modules/auth/services/access'
import { ContentErrorType } from '~/modules/auth/services/access'

interface MaybeVideoSignUpWrapperProps {
  videoToken?: string | null
  contentError?: ContentError | null
  videoTitle?: string
  children: ReactElement
}

const MaybeVideoSignUpWrapper = ({ videoToken, children, videoTitle, contentError }: MaybeVideoSignUpWrapperProps) => {
  const { currentUser, currentCustomerAccessPermission } = useRootData() || {}
  const { isCustomerAdmin } = currentCustomerAccessPermission || {}

  return videoToken ? (
    children
  ) : (
    <Dialog
      title={<span className="text-2xl font-bold">Watch hundreds of videos like {videoTitle} with Cub Club</span>}
      position="central"
      className="w-auto"
      content={
        <>
          {contentError && (
            <>
              {contentError.code === ContentErrorType.SignupUpRequired && (
                <SignUpForm className="bg-white dark:bg-baseGray" transparentPlanChooser transparentConsentForm />
              )}

              {contentError.code === ContentErrorType.CustomerMissing && (
                <>
                  <div className="mx-auto max-w-md space-y-6">
                    <Banner title="Subscription required" intent="warning">
                      Sorry, you need a paid subscription to access this resource.
                    </Banner>
                  </div>

                  <div className="mt-5 flex flex-col items-center">
                    <SignUpForm
                      className="bg-white dark:bg-baseGray"
                      defaultEmail={currentUser?.email}
                      transparentPlanChooser
                      transparentConsentForm
                      hideLoginLink
                    />
                  </div>
                </>
              )}

              {contentError.code === ContentErrorType.AccountNotActivated && (
                <>
                  <div className="mx-auto max-w-md space-y-6">
                    <Banner title="Active account required" intent="warning">
                      You haven't activated your account yet. Please check your email for the activation link.
                    </Banner>
                  </div>

                  <div className="mt-5 flex flex-col items-center">
                    <ResendActivationLinkButton />
                  </div>
                </>
              )}

              {contentError.code === ContentErrorType.SubscriptionPaymentIssue && (
                <>
                  <div className="mx-auto max-w-md space-y-6">
                    <Banner title="Payment issue" intent="warning">
                      <p>There is a payment issue with your subscription.</p>
                      {!isCustomerAdmin && (
                        <p>
                          Please contact your P&S administrator to resolve payment issues and regain access to Cub Club.
                        </p>
                      )}
                    </Banner>
                  </div>

                  {isCustomerAdmin && (
                    <div className="mt-5 flex flex-col items-center">
                      <Button to="/cub-club/account/subscription" intent="primary" text="View Subscription Details" />
                    </div>
                  )}
                </>
              )}

              {(contentError.code === ContentErrorType.SubscriptionLapsed ||
                contentError.code === ContentErrorType.SubscriptionMissing ||
                contentError.code === ContentErrorType.FreeAccessExpired) && (
                <>
                  <div className="mx-auto max-w-md space-y-6">
                    <Banner title="Subscription required" intent="warning">
                      Sorry, you need an active subscription to access this resource.
                    </Banner>
                  </div>

                  <div className="mt-5 flex flex-col items-center">
                    <RenewSubscriptionPanel />
                  </div>
                </>
              )}

              {contentError.code === ContentErrorType.NotAvailable && (
                <>
                  <div className="mx-auto max-w-md space-y-6">
                    <Banner title="Video not available" intent="warning">
                      Sorry, this resource is not currently available.
                    </Banner>
                  </div>
                </>
              )}

              {contentError.code === ContentErrorType.InsufficientPermissions && (
                <>
                  <div className="mx-auto max-w-md space-y-6">
                    <Banner title="Video not available" intent="warning">
                      {contentError.message}
                    </Banner>
                  </div>
                </>
              )}
            </>
          )}
        </>
      }
      trigger={
        <button className="z-20 rounded-xl" onClick={event => event.stopPropagation()}>
          {children}
        </button>
      }
    />
  )
}

export default MaybeVideoSignUpWrapper
