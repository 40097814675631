import { useLocation, NavLink } from '@remix-run/react'
import cx from 'classnames'

import { checkIfInactive } from '~/modules/auth/services/access'
import useRootData from '~/hooks/useRootData'
import { SignupStatus } from '~/modules/signup/types/signup-status'
import ContinueToPaymentButton from '~/modules/signup/components/ContinueToPaymentButton'

const navLinkClasses = cx(
  'flex items-center rounded-full bg-lightOrange px-3 py-1 text-dark transition-all duration-200 md:px-4',
  'hover:bg-baseOrange hover:shadow-lg'
)

const ContinueSignupNavLink = () => {
  const location = useLocation()
  const { currentUser, currentCustomer } = useRootData() || {}
  const { signupStatus, subscriptionStatus, validFreeAccess } = currentCustomer || {}

  const isOnboardingInProgress = !!currentCustomer
  const isLoggedIn = !!currentUser
  const isOnboardingPage =
    ['/cub-club/join', '/cub-club/activate'].some(path => location.pathname.startsWith(path)) ||
    location.pathname === '/cub-club'

  // Early return if we shouldn't show the component
  if (!isOnboardingInProgress || !signupStatus || isOnboardingPage) {
    if (isLoggedIn && !isOnboardingPage && (!subscriptionStatus || checkIfInactive(subscriptionStatus))) {
      return (
        <li className="ml-2 font-bold">
          <NavLink to="/cub-club" className={navLinkClasses}>
            <p className="ml-1 text-sm md:text-base">Create Account</p>
          </NavLink>
        </li>
      )
    }
    return null
  }

  // Render continue signup or payment button based on status
  const renderSignupButton = () => {
    if (signupStatus === SignupStatus.DETAILS_ENTERED) {
      return (
        <NavLink to="/cub-club/join/activate" className={navLinkClasses}>
          <p className="ml-1 text-sm md:text-base">Continue Signup</p>
        </NavLink>
      )
    }

    if (signupStatus === SignupStatus.ACCOUNT_ACTIVATED && !validFreeAccess) {
      return <ContinueToPaymentButton />
    }

    return null
  }

  return <li className="ml-2 font-bold">{renderSignupButton()}</li>
}

export default ContinueSignupNavLink
